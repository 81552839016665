/* @flow */
//import logo from './logo.svg';
import "./App.css";

import React from "react";
import Layout from "./app/Layout";
//import Login from './app/Login';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jwt: localStorage.getItem("token"),
    };
  }

  render() {
    /*const {jwt} = this.state;
        // We need to login
        if (jwt == null) {
            return (
                <Login />
            );
        }*/
    return <Layout />;
  }
}

export default App;
