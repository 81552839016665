import React from "react";
import { Switch, Route } from "react-router-dom";
//import Home from './../Home'
import Plasmids from "./../Plasmids";
const Router = () => (
  <Switch>
    <Route exact path="/" component={Plasmids} />
  </Switch>
);
export default Router;
