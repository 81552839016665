import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

import Site from "./layout/Site";
import Header from "./layout/Header";
import Content from "./layout/Content";
import Footer from "./layout/Footer";
import Router from "./layout/Router";

const Layout = ({ children }) => (
  <Site>
    <Helmet title="Gladstone Plasmid Database" />
    <Header />
    <Content>
      <Router />
    </Content>
    <Footer />
  </Site>
);

Layout.propTypes = {
  children: PropTypes.func,
};

export default Layout;
