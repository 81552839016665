import React from "react";

import axios from "axios";

import ReactTable from "react-table";
import "react-table/react-table.css";

import Login from "./Login";

import { Field, Label, Control, Input, TextArea } from "bloomer";
import { Notification } from "bloomer";

import {
  Button,
  Columns,
  Column,
  Container,
  Message,
  MessageHeader,
  MessageBody,
  Modal,
  ModalCard,
  ModalCardBody,
  ModalCardTitle,
  ModalCardHeader,
  ModalCardFooter,
  ModalBackground,
  ModalContent,
  ModalClose,
} from "bloomer";

// import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import matchSorter from "match-sorter";

const RequestState = Object.freeze({
  PROCESSING: Symbol("processing"),
  SUCCESS: Symbol("success"),
  FAILED: Symbol("failed"),
  NOTHING: Symbol("nothing"),
});

axios.defaults.headers.common["Authorization"] = "Bearer".concat(
  " ",
  localStorage.getItem("token"),
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log("Unauthorized - removing bearer token");
      localStorage.removeItem("token");
    }
    return error;
  },
);

class EditPlasmidForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updatePlasmidFunc: props.updatePlasmidFunc,
      closeModalFunc: props.closeModalFunc,
      id: props.plasmid.plasmid_id,
      name: props.plasmid.plasmid_name,
      desc: props.plasmid.aka,
      loc_alpha: props.plasmid.loc_alpha,
      loc_number: props.plasmid.loc_num,
      status: RequestState.NOTHING,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(() => ({
      [name]: value,
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState(() => ({
      status: RequestState.PROCESSING,
    }));

    const { id, name, desc, loc_alpha, loc_number } = this.state;
    axios
      .post(`/api/plasmid/${id}`, {
        plasmid_name: name,
        loc_alpha: loc_alpha,
        loc_num: loc_number,
        aka: desc,
      })
      .then((res) => {
        this.setState(() => ({
          message: `Successfully edited ${name}`,
          status: RequestState.SUCCESS,
        }));
        this.state.updatePlasmidFunc(res.data);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(err.message);
        }

        this.setState(() => ({
          message: `Problem editing ${name}. Please try again.`,
          status: RequestState.FAILED,
        }));
      });
  };

  render() {
    const { name, desc, loc_alpha, loc_number, status } = this.state;
    return (
      <div>
        {status && status === RequestState.SUCCESS && (
          <Notification isColor="success">{this.state.message}</Notification>
        )}
        {status && status === RequestState.FAILED && (
          <Notification isColor="warning">{this.state.message}</Notification>
        )}
        <form className="control" onSubmit={this.handleSubmit}>
          <Field>
            <Label>Plasmid Name</Label>
            <Control>
              <Input
                type="text"
                name="name"
                value={name}
                onChange={this.handleChange}
              />
            </Control>
          </Field>
          <Field>
            <Label>Plasmid Description</Label>
            <Control>
              <TextArea name="desc" onChange={this.handleChange} value={desc} />
            </Control>
          </Field>
          <Field>
            <Label>Location Letter</Label>
            <Control>
              <Input
                type="text"
                name="loc_alpha"
                value={loc_alpha}
                onChange={this.handleChange}
              />
            </Control>
          </Field>
          <Field>
            <Label>Location Number</Label>
            <Control>
              <Input
                type="text"
                name="loc_number"
                value={loc_number}
                onChange={this.handleChange}
              />
            </Control>
          </Field>
          <Field isGrouped>
            <Control>
              <Button
                type="submit"
                isLoading={status === RequestState.PROGRESSING}
                isColor="primary"
              >
                Update
              </Button>
            </Control>
            <Control>
              <Button isLink onClick={this.state.closeModalFunc}>
                Close
              </Button>
            </Control>
          </Field>
        </form>
      </div>
    );
  }
}

class AddPlasmidForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      launchModalFunc: props.launchModalFunc,
      closeModalFunc: props.closeModalFunc,
      updateCallback: props.updateCallback,
      name: "",
      desc: "",
      loc_alpha: "",
      loc_number: "",
      pdf: "",
      html: "",
      status: RequestState.NOTHING,
    };
    this.baseState = this.state;
  }

  regenKey() {
    let key = Math.random().toString(12);
    this.setState({
      inputKey: key,
    });
  }

  handleClose = (e) => {
    this.reset();
    this.regenKey();
    this.state.closeModalFunc();
  };

  reset() {
    this.setState(this.baseState);
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "pdf":
        this.setState({ pdf: e.target.files[0] });
        break;
      case "html":
        this.setState({ html: e.target.files[0] });
        break;
      default:
        this.setState(() => ({
          [name]: value,
        }));
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState(() => ({
      status: RequestState.PROCESSING,
    }));

    const { name, desc, loc_alpha, loc_number, pdf, html } = this.state;
    let formData = new FormData();
    formData.append("plasmid_name", name);
    formData.append("loc_alpha", loc_alpha);
    formData.append("loc_num", loc_number);
    formData.append("aka", desc);
    formData.append("pdf", pdf);
    formData.append("html", html);
    console.log(pdf);
    console.log(html);
    axios.post("/api/plasmid", formData).then((res) => {
      this.state.updateCallback(res.data.result);
      this.reset();
      this.regenKey();
      this.setState(() => ({
        message: `Successfully added ${name}.`,
        status: RequestState.SUCCESS,
      }));
    });
  };

  render() {
    const { name, desc, loc_alpha, loc_number, status, pdf, html } = this.state;
    return (
      <div>
        {status && status === RequestState.SUCCESS && (
          <Notification isColor="success">{this.state.message}</Notification>
        )}
        {status && status === Request.FAILED && (
          <Notification isColor="warning">{this.state.message}</Notification>
        )}
        <form className="control" onSubmit={this.handleSubmit}>
          <Field>
            <Label>Plasmid Name</Label>
            <Control>
              <Input
                type="text"
                name="name"
                value={name}
                onChange={this.handleChange}
              />
            </Control>
          </Field>
          <Field>
            <Label>Plasmid Description</Label>
            <Control>
              <TextArea
                name="desc"
                placeholder="Description of plasmid..."
                onChange={this.handleChange}
                value={desc}
              />
            </Control>
          </Field>
          <Field>
            <Label>Location Letter</Label>
            <Control>
              <Input
                type="text"
                name="loc_alpha"
                value={loc_alpha}
                onChange={this.handleChange}
              />
            </Control>
          </Field>
          <Field>
            <Label>Location Number</Label>
            <Control>
              <Input
                type="text"
                name="loc_number"
                value={loc_number}
                onChange={this.handleChange}
              />
            </Control>
          </Field>
          <Field>
            <Label>PDF File</Label>
            <Control>
              <Input
                type="file"
                key={this.state.inputKey || ""}
                name="pdf"
                onChange={this.handleChange}
                defaultValue=""
              />
            </Control>
          </Field>
          <Field>
            <Label>HTML File</Label>
            <Control>
              <Input
                type="file"
                key={this.state.inputKey || ""}
                name="html"
                onChange={this.handleChange}
                defaultValue=""
              />
            </Control>
          </Field>
          <Field isGrouped>
            <Control>
              {" "}
              <Button type="submit" isColor="primary">
                Add
              </Button>{" "}
            </Control>
            <Control>
              <Button isLink onClick={this.handleClose}>
                Close
              </Button>
            </Control>
          </Field>
        </form>
      </div>
    );
  }
}

const LogoutModal = ({ isActive, closeModalFunc }) => {
  const logoutFunc = () => {
    localStorage.clear();
    closeModalFunc();
    window.location.reload();
  };

  return (
    <Modal isActive={isActive}>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Logout?</ModalCardTitle>
        </ModalCardHeader>
        <ModalCardBody>Confirm logout?</ModalCardBody>
        <ModalCardFooter>
          <Button onClick={closeModalFunc} isColor="info">
            Cancel
          </Button>
          <Button onClick={logoutFunc} isColor="danger">
            Logout
          </Button>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

const AddModal = ({ isActive, launchModal, updateCallback, closeModal }) => {
  return (
    <Modal isActive={isActive}>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Add New Plasmid</ModalCardTitle>
        </ModalCardHeader>
        <ModalCardBody>
          <AddPlasmidForm
            launchModalFunc={launchModal}
            closeModalFunc={closeModal}
            updateCallback={updateCallback}
          />
        </ModalCardBody>
      </ModalCard>
    </Modal>
  );
};

const EditModal = ({ isActive, launchModal, updateCallback, plasmid }) => {
  if (plasmid === null) {
    return null;
  }
  return (
    <Modal isActive={isActive}>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>Edit Plasmid</ModalCardTitle>
        </ModalCardHeader>
        <ModalCardBody>
          <EditPlasmidForm
            updatePlasmidFunc={updateCallback}
            closeModalFunc={launchModal}
            plasmid={plasmid}
          />
        </ModalCardBody>
      </ModalCard>
    </Modal>
  );
};

const LoginModal = ({ isActive, closeModalFunc }) => {
  return (
    <Modal isActive={isActive}>
      <ModalBackground />
      <ModalContent>
        <ModalCardHeader>
          <ModalCardTitle>Login</ModalCardTitle>
        </ModalCardHeader>
        <ModalCardBody>
          <Login closeModalFunc={closeModalFunc} />
        </ModalCardBody>
      </ModalContent>
      <ModalClose />
    </Modal>
  );
};

class Plasmids extends React.Component {
  state = {
    isActiveLogin: false,
    isActiveLogout: false,
    isActiveAdd: false,
    isActiveEdit: false,
    activePlasmid: null,
    search: "",
    columns: [
      {
        Header: "Entry Number",
        accessor: "plasmid_id",
        show: false,
      },
      {
        Header: "Plasmid Name",
        accessor: "plasmid_name",
        width: 180,
        headerStyle: { "font-weight": "bold" },
        style: { "font-weight": "bold" },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ["plasmid_name"],
            threshold: matchSorter.rankings.CONTAINS,
          }),
        filterAll: true,
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            placeholder="Type to search..."
            value={filter ? filter.value : ""}
            onChange={(event) => onChange(event.target.value)}
          />
        ),
      },
      {
        Header: "Location Letter",
        accessor: "loc_alpha",
        headerStyle: { "white-space": "unset", "font-weight": "bold" },
        width: 100,
      },
      {
        Header: "Location Number",
        accessor: "loc_num",
        headerStyle: { "white-space": "unset", "font-weight": "bold" },
        width: 100,
      },
      {
        Header: "Description",
        accessor: "aka",
        headerStyle: { "font-weight": "bold" },
        style: { "white-space": "unset" },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ["aka"],
            threshold: matchSorter.rankings.CONTAINS,
          }),
        filterAll: true,
      },
      {
        Header: "PDF File",
        accessor: "field5",
        headerStyle: { "font-weight": "bold" },
        filterable: false,
        Cell: (row) =>
          row.value && (
            <a
              href={"/pdfs/" + row.value}
              rel="noopener noreferrer"
              target="_blank"
            >
              {row.value}
            </a>
          ),
      },
      {
        Header: "HTML File",
        accessor: "field6",
        headerStyle: { "font-weight": "bold" },
        filterable: false,
        Cell: (row) => (
          <a
            href={"/html/" + row.value}
            rel="noopener noreferrer"
            target="_blank"
          >
            {row.value}
          </a>
        ),
      },
      {
        Header: "Actions",
        headerStyle: { "font-weight": "bold" },
        filterable: false,
        show: localStorage.getItem("token") !== null,
        Cell: (row) => (
          <div style={{ margin: "5px" }}>
            <Button
              onClick={() => this.openEditModal(row.original)}
              isColor="info"
            >
              Edit
            </Button>
          </div>
        ),
      },
    ],
    plasmids: [],
  };

  closeLoginModal = () =>
    this.setState((prevState) => ({
      ...prevState,
      isActiveLogin: false,
    }));

  closeLogoutModal = () =>
    this.setState((prevState) => ({
      ...prevState,
      isActiveLogout: false,
    }));

  openEditModal(plasmid) {
    this.setState((prevState) => ({
      ...prevState,
      isActiveEdit: true,
      activePlasmid: plasmid,
    }));
  }

  closeEditModal = () =>
    this.setState((state) => ({
      ...state,
      isActiveEdit: false,
      activePlasmid: null,
    }));

  launchAddModal = () =>
    this.setState((state) => ({
      ...state,
      isActiveAdd: true,
    }));

  closeAddModal = () =>
    this.setState((state) => ({
      ...state,
      pdf: null,
      html: null,
      isActiveAdd: false,
    }));

  launchLoginModal = () =>
    this.setState((state) => ({
      ...state,
      isActiveLogin: !this.state.isActiveLogin,
    }));

  launchLogoutModal = () =>
    this.setState((state) => ({
      ...state,
      isActiveLogout: !this.state.isActiveLogout,
    }));

  updatePlasmid = (plasmid) => {
    let newPlasmids = [...this.state.plasmids];
    // find plasmid with number and update it
    const idx = newPlasmids.findIndex(
      (e) => e.plasmid_id === plasmid.plasmid_id,
    );
    newPlasmids[idx] = plasmid;
    this.setState({
      ...this.state,
      plasmids: newPlasmids,
    });
  };

  addPlasmid = (plasmid) => {
    let newPlasmids = [...this.state.plasmids];
    newPlasmids.unshift(plasmid);
    this.setState({ ...this.state, plasmids: newPlasmids });
  };

  componentDidMount() {
    this.fetchPlasmids();
  }

  fetchPlasmids = () => {
    axios.get("/api/plasmids").then((res) => {
      this.setState(res.data);
    });
  };

  matchSorterAcrossKeys(list, search, options) {
    const joinedKeysString = (item) =>
      options.keys.map((key) => item[key]).join(" ");
    return matchSorter(list, search, {
      ...options,
      keys: [...options.keys, joinedKeysString],
    });
  }

  render() {
    // if not logged in, we do not want to present certain buttons
    const isLoggedOut = localStorage.getItem("token") === null;
    let plasmids = this.state.plasmids;
    if (this.state.search) {
      plasmids = plasmids.filter((row) => {
        let terms = this.state.search.replace(/\s+/g, " ").trim().split(" ");
        var name_contains = terms.every((term) =>
          row.plasmid_name.toLowerCase().includes(term.toLowerCase()),
        );
        var aka_contains = row.aka
          ? terms.every((term) =>
              row.aka.toLowerCase().includes(term.toLowerCase()),
            )
          : false;
        return name_contains || aka_contains;
      });
    }

    return (
      <Container>
        {isLoggedOut ? (
          <div>
            <Message isColor="info">
              <MessageHeader>
                <p>You are in Read-Only Mode</p>
              </MessageHeader>
              <MessageBody>
                You are currently able to view, but not edit, plasmids. If you'd
                like to Add or Edit plasmid data, please login using the button
                below.
              </MessageBody>
            </Message>
            <br />
          </div>
        ) : (
          <br />
        )}

        <Columns>
          <Column isSize="2/3">
            <Input
              type="text"
              placeholder="Type to filter plasmid name and descriptions..."
              isSize="medium"
              isColor="info"
              value={this.state.search}
              onChange={(e) => this.setState({ search: e.target.value })}
            />
          </Column>
          <Column hasTextAlign="right">
            {isLoggedOut ? (
              <div>
                <Button isColor="primary" onClick={this.launchLoginModal}>
                  Login
                </Button>
              </div>
            ) : (
              <div>
                <span>
                  <Button isColor="success" onClick={this.launchAddModal}>
                    Add New Plasmid
                  </Button>
                  <Button
                    isColor="danger"
                    style={{ marginLeft: "10px" }}
                    onClick={this.launchLogoutModal}
                  >
                    Logout
                  </Button>
                </span>
              </div>
            )}
          </Column>
        </Columns>
        <ReactTable
          showPaginationTop={true}
          data={plasmids}
          columns={this.state.columns}
          defaultPageSize={100}
          className="-striped -highlight"
        />
        <LoginModal
          isActive={this.state.isActiveLogin}
          closeModalFunc={this.closeLoginModal}
        />
        <LogoutModal
          isActive={this.state.isActiveLogout}
          closeModalFunc={this.closeLogoutModal}
        />
        <AddModal
          isActive={this.state.isActiveAdd}
          launchModal={this.launchAddModal}
          closeModal={this.closeAddModal}
          updateCallback={this.addPlasmid}
        />
        <EditModal
          updateCallback={this.updatePlasmid}
          isActive={this.state.isActiveEdit}
          launchModal={this.closeEditModal}
          plasmid={this.state.activePlasmid}
        />
      </Container>
    );
  }
}

export default Plasmids;
