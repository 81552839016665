import React from "react";

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="content has-text-centered">
        <p>&copy; Gladstone Institutes 2019-2024</p>
        <p>All Rights Reserved</p>
      </div>
    </div>
  </footer>
);

export default Footer;
