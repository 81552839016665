import React from "react";

import axios from "axios";

import { Notification, Field, Label, Control, Input, Button } from "bloomer";

const RequestState = Object.freeze({
  PROCESSING: Symbol("processing"),
  SUCCESS: Symbol("success"),
  FAILED: Symbol("failed"),
  NOTHING: Symbol("nothing"),
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.username,
      password: props.password,
      closeModalFunc: props.closeModalFunc,
      status: RequestState.NOTHING,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(() => ({
      [name]: value,
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState(() => ({
      status: RequestState.PROCESSING,
    }));

    const { username, password } = this.state;
    axios
      .post("/login", {
        user_id: username,
        password: password,
      })
      .then((res) => {
        console.log(res);
        console.log("Successfully authenticated");
        this.setState(() => ({
          status: RequestState.SUCCESS,
        }));
        localStorage.setItem("token", res.data.token);
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err, "Problem logging in");
        this.setState(() => ({
          status: RequestState.FAILED,
        }));
      });
  };

  render() {
    const { username, password, status } = this.state;

    return (
      <div>
        {status && status === RequestState.NOTHING && (
          <Notification isColor="primary">
            You are not logged in. Please log in below.
          </Notification>
        )}
        {status && status === RequestState.FAILED && (
          <Notification isColor="warning">
            Invalid credentials. Please try again.
          </Notification>
        )}
        {status && status === RequestState.SUCCESS && (
          <Notification isColor="success">
            Successfully logged in! Redirecting...
          </Notification>
        )}
        <form className="control" onSubmit={this.handleSubmit}>
          <Field>
            <Label>Username</Label>
            <Control>
              <Input
                type="text"
                name="username"
                placeholder="User name"
                value={username}
                size="40"
                onChange={this.handleChange}
              />
            </Control>
          </Field>
          <Field>
            <Label>Password</Label>
            <Control>
              <Input
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={this.handleChange}
              />
            </Control>
          </Field>
          <Field isGrouped>
            <Control>
              <Button
                type="submit"
                isLoading={status === RequestState.PROGRESSING}
                isColor="primary"
              >
                Log In
              </Button>
            </Control>
            <Control>
              <Button isLink onClick={this.state.closeModalFunc}>
                Close
              </Button>
            </Control>
          </Field>
        </form>
      </div>
    );
  }
}

export default Login;
